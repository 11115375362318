
<template>
  <v-app>
    <cabecera/>
<v-main  style="background: #eef5f9">
  <v-container>
    <v-row>
      <v-col>
          <h2>1. INTRODUCCIÓN</h2>

          <p id="u154-8">PONTE SAC(en adelante “PONTE”), asegura la máxima reserva y protección de aquellos datos personales de los Usuarios del sitio en Internet de su propiedad: <a class="nonblock" href="http://www.ponte.pe">http://www.ponte.pe</a>, que ingresen al referido sitio Web.</p>
          <p id="u154-11">Este documento describe la “Política de Privacidad” que regula el tratamiento de los datos personales que los Usuarios registran en el sitio Web.</p>

          <h2>2. OBJETIVO Y FINALIDAD</h2>
          <p id="u154-18">En PONTE somos conscientes de la elevada consideración que tiene la privacidad de nuestros clientes y de todas aquellas personas que se interesan por nuestros productos y servicios. Siendo consecuentes con esta consideración tenemos el compromiso de respetar su privacidad y proteger la confidencialidad de su información privada y datos personales. Y es por ello que el objetivo de esta política de privacidad es dar a conocer a nuestros Usuarios la manera en que se recopilan, se tratan y se protegen los datos personales que a través de Internet son introducidos en el sitio Web a su disposición.</p>
          <p id="u154-21">La información personal privada no incluye información que está disponible a través de fuentes accesibles al público, es decir, de medios de comunicación electrónica, óptica y de otra tecnología concebidos para facilitar información al público y abiertos a la consulta general, como son: las guías telefónicas, los diarios y revistas, los medios de comunicación social, las listas profesionales, los repertorios de jurisprudencia anonimizados, los Registros Públicos administrados por la Superintendencia Nacional de Registros Públicos así como todo otro registro o banco de datos calificado como público conforme a ley, y la información que ostentan las entidades de la Administración Pública y que deba ser entregada a los administrados en aplicación de la Ley No 27806 - Ley de Transparencia y Acceso a la Información Pública.</p>
          <p id="u154-25">En PONTE estamos especialmente interesados en ofrecer a nuestros Usuarios el más alto nivel de seguridad y proteger la confidencialidad de los datos que nos aporta. Por ello, las transacciones comerciales son realizadas en un entorno de servidor seguro bajo protocolo SSL (Secure Socket Layer), actualmente empleado por las empresas más importantes del mundo para realizar transacciones electrónicas seguras.</p>

          <p id="u154-29">El sitio Web ha sido creado y diseñado con la finalidad que PONTE realice ofertas de bienes y servicios, así como facilitar otro tipo de información que creemos puede ser de interés y crear así un espacio de comunicación para los Usuarios.</p>

          <h2>3. LEGISLACIÓN</h2>
          <p id="u154-36">Esta política está regulada por la legislación peruana y en particular por:</p>
          <p id="u154-39">Ley N° 29733 – Ley de Protección de Datos Personales.</p>
          <p id="u154-41">Decreto Supremo N° 003-2013- JUS, Reglamento de la Ley N° 29733.</p>
          <p id="u154-43">Directiva de Seguridad de la Información, aprobada por la Resolución Directoral N° 019-2013- JUS/DGPDP.</p>

          <p id="u154-47">De acuerdo con la Ley N° 29733 – Ley de Protección de Datos Personales y su Reglamento aprobado por el Decreto Supremo N° 003-2013-JUS, se entiende por datos personales toda información numérica, alfabética, gráfica, fotográfica, acústica, sobre hábitos personales, o de cualquier otro tipo concerniente a una persona natural que la identifica o la hace identificable a través de medios que pueden ser razonablemente utilizados.</p>
          <p id="u154-50">Asimismo, se entiende por Tratamiento de Datos Personales a cualquier operación o procedimiento técnico, automatizado o no, que permite la recopilación, registro, organización, almacenamiento, conservación, elaboración, modificación, extracción, consulta, utilización, bloqueo, supresión, comunicación por transferencia o por difusión o cualquier otra forma de procesamiento que facilite el acceso, correlación o interconexión de los datos personales.</p>
          <p id="u154-53">PONTE desarrolla su política de tratamiento de datos personales en atención a los principios rectores establecidos en la Ley No 29733 -</p>
          <p id="u154-55">Ley de Protección de Datos Personales y por lo tanto:</p>
          <p id="u154-58">I.&nbsp; De acuerdo al principio de legalidad, rechaza la recopilación de los datos personales de nuestros Usuario por medios fraudulentos, desleales o ilícitos.</p>
          <p id="u154-61">II.&nbsp; Conforme al principio de consentimiento, en el tratamiento de los datos personales de nuestros Usuarios mediará su consentimiento.</p>
          <p id="u154-64">III.&nbsp; Los datos personales de nuestros Usuario se recopilarán para una finalidad determinada, explícita y lícita, y no se extenderá a otra finalidad que no haya sido la establecida de manera inequívoca como tal al momento de su recopilación, excluyendo los casos de actividades de valor histórico, estadístico o científico cuando se utilice un procedimiento de disociación o anonimización.</p>
          <p id="u154-67">IV.&nbsp; Todo tratamiento de datos personales de nuestros Usuarios será adecuado, relevante y no excesivo a la finalidad para la que estos hubiesen sido recopilados.</p>
          <p id="u154-70">V.&nbsp; Los datos personales que vayan a ser tratados serán veraces, exactos y, en la medida de lo posible, actualizados, necesarios, pertinentes y adecuados respecto de la finalidad para la que fueron recopilados. Se conservarán de forma tal que se garantice su seguridad y solo por el tiempo necesario para cumplir con la finalidad del tratamiento.</p>
          <p id="u154-73">VI.&nbsp; PONTE y, en su caso, los encargados de tratamiento, adoptan las medidas técnicas, organizativas y legales necesarias para garantizar la seguridad y confidencialidad de los datos personales. PONTE cuenta con las medidas de seguridad apropiadas y acorde con el tratamiento que se vaya a efectuar y con la categoría de datos personales de que se trate.</p>
          <p id="u154-76">VII.&nbsp; PONTE informa a sus Usuarios que pueden ejercer sus derechos contenidos en el derecho constitucional a la protección de datos personales en sede administrativa ante la Autoridad Nacional de Protección de Datos y en sede jurisdiccional ante el Poder Judicial a los efectos del inicio del correspondiente proceso de habeas data.</p>
          <p id="u154-79">VIII. PONTE garantiza el nivel adecuado de protección de los datos personales de sus Usuarios para el flujo transfronterizo de datos personales, con un mínimo de protección equiparable a lo previsto por la Ley No 29733 o por los estándares internacionales de la materia.</p>

          <h2>4. INFORMACIÓN</h2>
          <p id="u154-86">Se informa a los Usuarios que los datos personales que introduzcan en los formularios que se encuentran en el apartado “Contacto” del Web serán tratados por PONTE para poder gestionar los comentarios que nos hagan en éstos y para poder contactar con estos Usuarios, y se regirán por lo expuesto en esta Política de Privacidad.</p>
          <h2>5. CALIDAD DE LOS DATOS PERSONALES</h2>
          <p id="u154-93">Los datos personales solicitados a los Usuarios, son datos básicos de contacto y son adecuados, pertinentes y no excesivos en relación con la finalidad para los que se recogen. Los datos personales a los que PONTE tendrá acceso serán aquellos que el Usuario facilite voluntariamente rellenando los formularios puestos a tal efecto.</p>
          <p id="u154-96">PONTE les proporciona a sus Usuarios los recursos técnicos adecuados para que tomen conocimiento de la presente Política de Privacidad y de cualquier otra información que pueda ser relevante; constituyendo el ingreso de Datos Personales o información confidencial en el sitio Web, la manifestación expresa de su consentimiento a la presente Política de Privacidad.</p>
          <p id="u154-99">Los datos personales facilitados por los Usuarios tienen que ser exactos y correctos de forma que respondan con veracidad a su situación actual. En caso contrario estos datos serán cancelados.</p>
          <p id="u154-102">Los datos personales facilitados por los Usuarios son recopilados para el cumplimiento de las finalidades expuestas en este documento y no se usarán para otras finalidades incompatibles con las especificadas.</p>
          <p id="u154-105">Los datos personales serán cancelados cuando hayan dejado de ser necesarios para las finalidades para las cuales han sido recopilados. Sin embargo, se conservarán durante el tiempo en que pueda exigirse algún tipo de responsabilidad a PONTE derivada de esta relación con los Usuarios.</p>
        <p id="u154-105">Los datos personales son alojados en la base de datos de nuestro partner Tunay.</p>
          <h2>6. FINALIDAD DE LOS TRATAMIENTOS DE LA INFORMACIÓN PERSONAL</h2>
          <p id="u154-112">Las finalidades de tratamiento de los datos personales que los Usuarios introducen en los diferentes formularios del sitio Web son para poder dar contestación a las consultas, peticiones y otro tipo de información que nos realicen a través de éstos y poder facilitar a posteriori cualquier información que PONTE crea que puede ser de su interés.</p>
          <p id="u154-115">Asimismo, los datos personales proporcionados en el proceso de compra de productos serán tratados para el desarrollo, cumplimiento y ejecución del contrato de compraventa del producto adquirido, para proporcionar al Usuario información acerca de los productos de PONTE o de otras empresas del grupo (cuyas actividades se relacionan con los negocios de supermercados, tiendas por departamento, centros comerciales y servicios financieros), incluyendo, en relación con dichos productos, el envío de comunicación electrónica equivalente como SMS, mails y llamadas telefónicas.</p>
          <p id="u154-118">En caso el Usuario no desee recibir las comunicaciones comerciales señaladas, podrá comunicarlo en cualquier momento al correo:</p>
          <p id="u154-121"><a class="nonblock" href="mailto:info@ponte.pe">info@ponte.pe</a></p>
          <h2>7. TRATAMIENTO DE DATOS PERSONALES Y CONSENTIMIENTO</h2>
          <p id="u154-128">Los datos personales facilitados por los Usuarios se almacenarán en los bancos de datos que forman parte del sistema de información de PONTE y serán tratados para poder llevar a cabo las finalidades expuestas anteriormente.</p>
          <p id="u154-131">Los bancos de datos que contienen datos personales se inscriben en el Registro de Protección de Datos de la Autoridad de Protección de Datos Personales.</p>
          <p id="u154-134">Los datos personales que faciliten los Usuarios sólo podrán ser conocidos y manejados por el personal de PONTE que necesite conocer dicha información para poder contestar las solicitudes de los Usuario. Estos datos personales serán tratados de forma leal y lícita y no serán utilizados para otras finalidades incompatibles con las especificadas.</p>

          <h2>8. COMUNICACIÓN POR TRANSFERENCIA DE DATOS PERSONALES</h2>
          <p id="u154-141">PONTE respeta la privacidad de sus Usuarios, no compartiremos su información con terceros si usted no desea expresamente que lo hagamos, salvo en el caso de empresas relacionadas al grupo empresarial al que pertenecemos y a con empresas subcontratadas para la entrega de productos y asistencia de servicio al cliente.</p>
          <p id="u154-144">Informamos a los Usuarios que los datos personales que nos faciliten no serán comunicados por transferencia a ningún tercero que no sea parte del grupo PONTE o empresas subcontratadas para la entrega de productos y asistencia de servicio al cliente.</p>
          <p id="u154-147">Asimismo, es conveniente que los Usuarios sepan que sus datos personales podrán ser comunicados por transferencia a las entidades administrativas, autoridades judiciales y/o policiales, siempre y cuando esté establecido por Ley.</p>
          <h2>9. CONFIDENCIALIDAD DE LOS DATOS PERSONALES</h2>
          <p id="u154-154">Los datos personales facilitados por los Usuarios serán tratados con total confidencialidad. PONTE se compromete a guardar secreto profesional indefinidamente respecto de los mismos y garantiza el deber de guardarlos adoptando todas las medidas de seguridad necesarias.</p>
          <h2>10. SEGURIDAD DE LOS DATOS PERSONALES</h2>
          <p id="u154-161">En cumplimiento de la normativa vigente PONTE ha adoptado las medidas técnicas de seguridad y confidencialidad apropiadas a la categoría de los datos personales, necesarias para mantener el nivel de seguridad requerido con el objetivo de evitar la alteración, pérdida o el tratamiento o accesos no autorizados que puedan afectar a la integridad, confidencialidad y disponibilidad de la información.</p>
          <p id="u154-164">PONTE tiene implementadas todas las medidas de índole legal, técnica y organizativa necesarias para garantizar la seguridad de los datos personales y evitar su alteración, pérdida y tratamiento y/o acceso no autorizado, teniendo en cuenta el estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya sea que provengan de la acción humana, del medio físico o natural, tal y como establece la legislación peruana vigente de protección de datos personales.</p>
          <p id="u154-167">La empresa también tiene implementadas medidas de seguridad adicionales para reforzar la confidencialidad e integridad de la información y continuamente mantiene la supervisión, control y evaluación de los procesos para asegurar la privacidad de los datos personales.</p>
          <p id="u154-170">Sin embargo, la transmisión de información mediante las redes de comunicación y de Internet no es totalmente segura; por eso, y a pesar de que PONTE realizará sus mejores esfuerzos para proteger los datos personales, no puede garantizar la seguridad de los mismos durante el tránsito hasta el sitio Web. En tal sentido, toda la información que los Usuarios proporcionen, se enviará por su cuenta y riesgo.</p>
          <p id="u154-173">Es por ello que PONTE recomienda la máxima diligencia a sus Usuarios cuando trasladen a terceros o publiquen información personal para evitar poner en riesgo sus datos personales, eludiendo PONTE toda responsabilidad en caso de sustracciones, modificaciones o pérdidas de datos ilícitas.</p>

          <h2>11. EJERCICIO DE DERECHOS</h2>
          <p id="u154-180">Los Usuarios que hayan facilitado sus datos personales a PONTE pueden dirigirse a esta empresa, con el fin de poder ejercer sus derechos de información, de acceso, de actualización, inclusión, rectificación y supresión, de impedir el suministro de sus datos personales, de oposición al tratamiento o de tratamiento objetivo de los datos, en los términos recogidos en la legislación peruana vigente.</p>
          <p id="u154-183">El Usuario podrá ejercer sus derechos presentando una solicitud escrita en nuestra oficina principal ubicada en Calle Manuel Bonilla No 116 Miraflores - Lima, conforme al “Formato Modelo para el Ejercicio de Derechos ARCO” en el horario establecido para la atención al público. Se podrán establecer otros canales para tramitar estas solicitudes, lo que será informado oportunamente por el PONTE.</p>
          <h2>12. MENORES DE EDAD</h2>
          <p id="u154-190">PONTE entiende la importancia de proteger la privacidad de los niños, especialmente en un entorno online. Por este motivo, el sitio Web no está diseñado ni dirigido a menores de 20 años.</p>
          <p id="u154-193">PONTE no llevará a cabo voluntariamente el tratamiento de datos personales relativos a menores de edad. En el supuesto que se tenga conocimiento que los datos personales recogidos corresponden a un menor de edad sin autorización, se adoptarán las medidas oportunas para eliminar estos datos tan pronto como sea posible.</p>
          <h2>13. CONSENTIMIENTO</h2>
          <p id="u154-200">Al aceptar esta Política de Privacidad, los Usuarios están de acuerdo con todos los aspectos expuestos en este documento y nos autorizan a tratar sus datos de carácter personal para las finalidades expuestas anteriormente.</p>
          <h2>14. OTROS</h2>
          <p id="u154-207">Para realizar cualquier tipo de consulta respecto a esta política puede dirigirse a la siguiente dirección de correo electrónico:</p>
          <p id="u154-210"><a class="nonblock" href="mailto:info@ponte.pe">info@ponte.pe</a></p>
          <h2>15. VIGENCIA Y MODIFICACIÓN DE LA PRESENTE POLÍTICA DE PRIVACIDAD</h2>
          <p id="u154-217">La Política de Privacidad Web de PONTE ha sido actualizada el mes de Junio del 2017.</p>
          <p id="u154-220">PONTE se reserva el derecho a modificar su Política de Privacidad Web en el supuesto de que exista un cambio en la legislación vigente, doctrinal, jurisprudencial o por criterios propios empresariales. Si se introdujera algún cambio en esta Política, el nuevo texto se publicará en este mismo Web.</p>
          <p id="u154-223">Se recomienda a los Usuario que accedan periódicamente a esta Política de privacidad que encontraran en este mismo sitio Web.</p>


      </v-col>
    </v-row>
  </v-container>
</v-main>
<piepagina />
  </v-app>
</template>


<script>

import cabecera from './general/cabecera'
import piepagina from './general/piepagina';

export default {
  name: 'politicas',
  components: {
    cabecera,
    piepagina,

  },
  data: () => ({

  }),
}
</script>
